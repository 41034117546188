import React, { useState, useEffect } from 'react';
// import Logo from '../assets/images/XMLID_2_.svg';
import Logo from '../assets/images/imgr.svg';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import io from 'socket.io-client';
const socket = io(process.env.REACT_APP_BASE_URL);
var createGuest = require("cross-domain-storage/guest");
var createHost = require("cross-domain-storage/host");
var storageHost = createHost([
  {
    origin: "http://localhost:3000",
    allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: "http://localhost:3001",
    allowedMethods: ["get"],
  },
]);

const Login = (props) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [loading, setLoading] = useState(false);

    // const handleLogin = async () => {
    //     if (!email || !password) {
    //         toast.error('email or password required')
    //     }else{
    //         const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/login`,{
    //             method:'POST',
    //             headers:{'Content-Type':'application/json'},
    //             body:JSON.stringify({email,password})
    //         }).then(res => {return res.json()})
    //             .then(data => {return data})
    //             if (response.status){
    //                 const user = response.data
    //                     socket.emit("setup", user);
    //                     props.setToken(user?.token)
    //                     localStorage.setItem('userdata', JSON.stringify(user))
    //                     navigate('/')   
    //                 }else{
    //                 toast.error(response.message)
    //             }
    //     }  
    // }
    const handleLogin = async () => {
        if (!email || !password) {
            toast.error('Email or password required');
        } else {
            setLoading(true); // Set loading to true when login starts

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/login`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email, password }),
                }).then((res) => res.json());

                if (response.status) {
                    const user = response.data;
                    socket.emit('setup', user);
                    props.setToken(user?.token);
                    localStorage.setItem('userdata', JSON.stringify(user));
                    navigate('/');
                } else {
                    toast.error(response.message);
                }
            } catch (error) {
                console.error('Login error:', error);
                toast.error('An error occurred during login.');
            } finally {
                setLoading(false); // Set loading to false when login is complete (either success or failure)
            }
        }
    };

    const handleVisitor = async () => {
        await fetch(`${process.env.REACT_APP_BASE_URL}/admin/visitor`,{
            method:'POST'
        })
    }

    useEffect(() => {
        // const wpStorage = createGuest('http://localhost/')
        localStorage.removeItem('userdata')
        handleVisitor()
    },[])

    return (
        <>
        <ToastContainer/>
        {loading && (
        <div className="loader-overlay">
          <TailSpin
            height={80}
            width={80}
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius={1}
            visible={true}
          />
        </div>
      )}
        <div className='login-grid'>
            <div className='login-logo'>
                <img src={Logo} className='img-fluid' />
                 
            </div>
            <div className='login-welcome'>
                <div className='d-flex align-items-center h-100'>
                    <Col lg={9} className='mx-auto'>
                        <div>
                            <h2 className='fw-600 text-custom-grey'><span className='text-green'>Welcome </span>back!</h2>
                            <p className='text-custom-grey'>Login to get started</p>
                            <div className='login-form mt-5'>
                                {/* <Form> */}
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className='fw-600'>Email address</Form.Label>
                                        <Form.Control type="email" className='' placeholder="Enter email" autoComplete='true' onChange={(e) => setEmail(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Label className='fw-600'>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" autoComplete='false' onChange={(e) => setPassword(e.target.value)}/>
                                    </Form.Group>
                                    <Button variant="primary" type="submit" className='d-block bg-green rounded text-white w-100 border-0 fs-4 mt-5 fst-normal py-2 custom-shadow text-decoration-none text-center' onClick={handleLogin}>
                                        Login
                                    </Button>
                                    <div className='mt-sm-5 text-center pt-3'>
                                        <Link to="/" className='text-green d-inline me-5 fst-normal fs-5'>Terms & Conditions</Link>
                                        <Link to="/" className='text-green d-inline fst-normal fs-5'>Privacy Policy</Link>
                                    </div>
                                {/* </Form> */}
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
        </div>
        </>
    )
}
export default Login;
